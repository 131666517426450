let featuresAccordion = {
    init: function () {
        let headers = document.querySelectorAll('.prices-item-features-list');
        if (headers.length > 0) {
            for (let head of headers) {
                head.addEventListener('click', featuresAccordion.select);
            }
        }
    },

    select: function () {
        this.classList.toggle('active');
    },
};

featuresAccordion.init('.prices-item-features-list');

//footer

if (document.querySelector('.footer-top--description')) {
    document.querySelector('.footer-top--description--btn').addEventListener('click', () => {
        document.querySelector('.footer-top--description').classList.toggle('collapsed-f');
        document.querySelector('.footer-top--description--btn').classList.toggle('active');
    });
}

if (document.querySelector('.android-platform') && document.querySelector('.ios-platform')) {
    var androidPlatform = document.querySelector('.android-platform');
    var iosPlatform = document.querySelector('.ios-platform');
    var androidBlock = document.querySelector('.prices-block-android');
    var iosBlock = document.querySelector('.prices-block-ios');
    androidPlatform.addEventListener('click', function () {
        if (iosPlatform.classList.contains('active')) {
            iosPlatform.classList.remove('active');
            iosBlock.classList.remove('active');
            androidPlatform.classList.add('active');
            androidBlock.classList.add('active');
        }
    });
    iosPlatform.addEventListener('click', function () {
        if (androidPlatform.classList.contains('active')) {
            androidPlatform.classList.remove('active');
            androidBlock.classList.remove('active');
            iosPlatform.classList.add('active');
            iosBlock.classList.add('active');
        }
    });
}

function androidPremium() {
    if (document.querySelector('.prices-android-premium')) {
        document.querySelector('.prices-android-premium').classList.add('active');
        if (document.querySelector('.prices-android-basic').classList.contains('active')) {
            document.querySelector('.prices-android-basic').classList.remove('active');
        }
    }
}

function androidBasic() {
    if (document.querySelector('.prices-android-basic')) {
        document.querySelector('.prices-android-basic').classList.add('active');
        if (document.querySelector('.prices-android-premium').classList.contains('active')) {
            document.querySelector('.prices-android-premium').classList.remove('active');
        }
    }
}

function iosPremium() {
    if (document.querySelector('.prices-ios-premium')) {
        document.querySelector('.prices-ios-premium').classList.add('active');
        if (document.querySelector('.prices-ios-basic').classList.contains('active')) {
            document.querySelector('.prices-ios-basic').classList.remove('active');
        }
    }
}

function iosBasic() {
    if (document.querySelector('.prices-ios-basic')) {
        document.querySelector('.prices-ios-basic').classList.add('active');
        if (document.querySelector('.prices-ios-premium').classList.contains('active')) {
            document.querySelector('.prices-ios-premium').classList.remove('active');
        }
    }
}

if (document.querySelector('#ab1m')) {
    document.querySelector('#ab1m').addEventListener('change', (e) => {
        if (document.querySelector('#ab1m').checked === true) {
            document.querySelector('#ab1m').checked = true;
            document.querySelector('#af1m').checked = false;
            document.querySelector('#af3m').checked = false;
            document.querySelector('#af12m').checked = false;
            androidBasic();
        }
    });
    document.querySelector('#af1m').addEventListener('change', (e) => {
        if (document.querySelector('#ab1m').checked === true) {
            document.querySelector('#ab1m').checked = false;
            androidPremium();
        }
    });
    document.querySelector('#af3m').addEventListener('change', (e) => {
        if (document.querySelector('#ab1m').checked === true) {
            document.querySelector('#ab1m').checked = false;
            androidPremium();
        }
    });
    document.querySelector('#af12m').addEventListener('change', (e) => {
        if (document.querySelector('#ab1m').checked === true) {
            document.querySelector('#ab1m').checked = false;
            androidPremium();
        }
    });
}

if (document.querySelector('#ib1m')) {
    document.querySelector('#ib1m').addEventListener('change', (e) => {
        if (document.querySelector('#ib1m').checked === true) {
            document.querySelector('#ib1m').checked = true;
            document.querySelector('#if1m').checked = false;
            document.querySelector('#if3m').checked = false;
            document.querySelector('#if12m').checked = false;
            iosBasic();
        }
    });
    document.querySelector('#if1m').addEventListener('change', (e) => {
        if (document.querySelector('#ib1m').checked === true) {
            document.querySelector('#ib1m').checked = false;
            iosPremium();
        }
    });
    document.querySelector('#if3m').addEventListener('change', (e) => {
        if (document.querySelector('#ib1m').checked === true) {
            document.querySelector('#ib1m').checked = false;
            iosPremium();
        }
    });
    document.querySelector('#if12m').addEventListener('change', (e) => {
        if (document.querySelector('#ib1m').checked === true) {
            document.querySelector('#ib1m').checked = false;
            iosPremium();
        }
    });
}
